import Cookies from 'es-cookie';
import { deserializeCF } from "./serializer";
const CF_COOKIE_NAME = 'userContext';

export const isTreatmentEnabled = (treatmentId) => {
    try {
        return getAndParseCookie().includes(treatmentId);
    } catch (e) {
        console.warn('ctc-reader in isTreatmentEnabled failed.', e);
    }
};

export const getAllTreatments = () => {
    try {
        return getAndParseCookie();
    } catch (e) {
        console.warn('ctc-reader in getAllTreatments failed.', e);
        return [];
    }
};

export default {
    isTreatmentEnabled,
    getAllTreatments
};

const getAndParseCookie = () => {
    const cfCookie = Cookies.get(CF_COOKIE_NAME);
    if (!cfCookie) {
        console.warn('ctc-reader: Context cookie are missing. If you are developing locally check the readme;)')
        return [];
    }
    return deserializeCF(cfCookie);
};

